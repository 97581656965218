// TillReiners <- Kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

@import './general';
@import './navigation';
@import './maki';
@import './nigiri';
@import './detail-pages';
@import './checkout';
@import './footer';

// OTHER

#header .navbar {
  padding: 0.75rem 1.5rem !important;
  .navbar-brand {
    img {
      // max-width: 130px;
      padding-left: 0;
      height: 5rem;
      max-height: 5rem;
      //filter: invert(1);

      &:hover,
      &:focus {
        // filter: opacity(0.66);
      }
    }
    @include media-breakpoint-down(sm) {
      img {
        height: 3rem;
        max-height: 3rem;
      }
      // margin: 0;
      // height: 3.5rem;
      // max-height: 3.5rem;
    }
  }
}

.header-image {
  background-image: url('./assets/images/tr_grandissimo_header.jpg');
  background-position: center center;
  background-size: cover;
  max-width: 1140px;
  padding: 0;
  height: 50vh;
  @media screen and (max-width: 768px) {
    background-position: center center;
  }
}

#product-listing {
  padding-top: 2rem;
}
.hero-slider {
  margin-bottom: 0;
}

.stage-wrapper {
  box-shadow: $box-shadow-xl;
  background-color: #fbccb0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.stage {
  box-shadow: $box-shadow-xl;
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

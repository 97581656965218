.checkout {
  .sticky-bottom {
    width: 100vw;
    max-width: 100vw;
    margin-left: calc(50% - 50vw);
  }
  ol.list-steps li.active:before {
    border: 0.5rem solid $tillblack;
  }
}

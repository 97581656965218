.maki {
  @extend .p-3;
  .card-title {
    text-align: left;
    .title {
      //text-transform: uppercase;
      font-size: $h3-font-size;
      font-weight: 500 !important;
      color: $tillblack;
    }
    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
    .price {
      color: $tillorange;
    }
  }
}
// hover effect
a.maki.card {
  border: 2px solid transparent !important;
  border-width: 2px !important;
  border-color: transparent !important;
  border-style: solid;
  border-radius: 4px !important;
  &:hover {
    box-shadow: $box-shadow-xl;
    border-color: rgba($color: $tillorange, $alpha: 0.3) !important;
    transition: all 0.15s ease-in-out;
  }
}

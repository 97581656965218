.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;

  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $tillblack;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .badge.sold-out-status {
      z-index: 2;
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h3-font-size;
    }

    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      // text-transform: uppercase;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        padding-left: 0;
        line-height: 2rem;
      }
      p {
        margin-bottom: 0px;
      }

      .title {
        font-size: 0.6em;
        margin-bottom: 0;
        color: $tillblack;
      }

      .location {
        font-size: $h3-font-size !important;
        font-weight: 500;
        color: $tillblack;

        .city {
          font-weight: 700;
        }
      }

      .nigiri-tags {
        font-size: 1.1rem;
        color: $tillwhite;
        position: absolute;
        top: -0.8rem;
        right: -0.4rem;
        @media screen and (max-width: 768px) {
          top: 5px;
          right: -8px;
        }
        z-index: 2;
        .tag {
          // margin-right: 10px;
        }
      }
    }

    .nigiri-cta .btn {
      color: $tillblack;
      border: 2px solid $tillblack;
      background: transparent;
      &:hover {
        background-color: $tillorange;
        color: $tillwhite;
      }
    }
  }
  // .subtitle {
  //   font-size: 0.5em;
  //   margin-bottom: 0;
  //   color: $tillblack;
  //   font-weight: normal;
  //   @include media-breakpoint-down(md) {
  //     line-height: 1rem;
  //     margin-bottom: 0.5rem;
  //   }
  // }
}

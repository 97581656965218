body {
  background-color: $tillwhite;
  font-family: $tillfont;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

::selection {
  background-color: $tillorange;
  color: $tillblack;
}

a {
  color: $tillblack;
}

#footer ul a,
#footer address a {
  &:hover {
    background-color: $tillorange;
  }
}

body.product-listing-page,
body.ticket-listing-page {
  h1 {
    padding-bottom: 4rem !important;
  }
}

ol.list-steps li.activatable:after,
ol.list-steps li.active:after {
  background: #caced1;
}

// Ticket Detail Page

.ticket-detail-page {
  .card {
    border-bottom: solid 4px $tillblack;
    border-radius: 0px;
  }
  .card.article-title {
    background-color: $tillwhite;
    .super-title {
      color: $tillblack;
    }
    h1 {
      color: $tillblack;
      font-family: $tillheading;
      font-size: $h2-font-size;
      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
      }
      font-weight: 500;
      small {
        color: $tillblack;
      }
    }
  }

  // TABS TICKET DETAIL

  article.ticket-detail {
    h2 {
      @extend .mb-0;
    }
    .nav-tabs {
      border-bottom: 4px solid $black;
    }
    .nav-tabs .nav-link {
      color: $gray-500;
      border: 0;
      border-bottom: 1px solid $black;
      @extend .py-3;
    }
    .nav-tabs .nav-link.active {
      //background: linear-gradient($black, $gray-700);
      color: $black;
      border-bottom: 1px solid $gray-700;
      &:hover {
        //color: lighten($primary, 10%);
        background: $gray-100;
        color: $black;
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid $gray-700;
      background: $gray-100;
      color: $gray-500;
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $tillblack;
    //text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $tillgrey;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $tillgrey;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

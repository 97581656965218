#header {
  // box-shadow: $box-shadow-xl;

  .navbar {
    padding: 1rem;
    font-size: $h5-font-size;
    background-color: $tillwhite !important;

    .navbar-brand {
      padding: 0;

      #logo {
        font-size: 2.8rem;
        color: $tillorange;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
          font-size: 1.6rem;
        }
      }

      img {
        padding-left: 0.7rem;
        height: 1.3rem;
        // max-height: 2rem;
        @include media-breakpoint-up(sm) {
          height: 3rem;
          max-height: 3rem;
        }
        @include media-breakpoint-up(md) {
          height: 4rem;
          max-height: 4rem;
        }
        &:hover,
        &:focus {
          // transform: skewX(-20deg);
        }
      }
    }

    .hamburger {
      color: $tillblack !important;
      z-index: 3;
      padding-bottom: 0.7rem;
      @include media-breakpoint-down(sm) {
        padding-left: 0px;
      }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner:after,
    .hamburger.is-active .hamburger-inner:before {
      background-color: $tillblack !important;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 3px;
    }

    .fal {
      font-weight: 500;
    }
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

// hide cart countdown on tiny displays
// .navbar .nav-link .badge.expiration {
//   display: none;
//   @media only screen and (min-width: 350px) {
//     display: inherit;
//   }
// }

.offcanvas-collapse {
  box-shadow: none;
  background-color: transparent;
  top: 0;

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    // background-color: rgba($color: $tillwhite, $alpha: 0.8) !important;
    font-size: $h2-font-size;
    // text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);

    a {
      color: $tillblack;
    }
  }
}

.offcanvas-collapse-left {
  background-color: $tillorange;
  border-right: 4px solid $tillblack;
  text-align: right;
  // min-width: 320px;
  // width: 22vw;
  width: clamp(320px, 22vw, 100vw);
  height: 100vh;
  bottom: auto;
  overflow: visible;
  ul {
    margin-top: 2rem;
  }
  .nav-item {
    padding-right: 1.75rem;
    margin-bottom: 0rem;
    line-height: 0.9;
  }
  .nav-item:hover {
    padding-right: 2.1rem;
  }
}

.offcanvas-collapse-right {
  background: $tillorange;
  border-left: $tillblack solid 4px;
  padding-top: 3rem;

  button.close {
    position: absolute;
    top: 23px;
    right: 23px;
    @include media-breakpoint-up(md) {
      top: 31px;
      right: 35px;
    }
  }
  .cart-listing {
    background: rgba(255, 255, 255);
  }
  .coupon-container button:hover {
    color: white;
  }
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

#footer {
  min-height: 30vh;
  background-color: $tillwhite;
  color: $tillblack;
  border-top: 4px solid $tillblack;

  a {
    color: $tillblack;
  }
  // border-bottom: 4px solid $primary;
}
